<template>
  <div class="hello" ref="appRef">
    <div class="head">
      <div class="logo">
        <img src="../assets/logo.png" />

        <ul>
          <li @click="go()">首页</li>
          <li @click="goguan()">关于我们</li>
          <li @click="gofang()">方案架构</li>
          <li @click="goyou()">核心优势</li>
          <li @click="gochan()">产品介绍</li>
          <li @click="gedian()">经典案例</li>
          <li @click="gohe()">合作伙伴</li>
        </ul>
      </div>
    </div>
    <div class="tet_l">
      <div class="tet_o">万物互联 无线智慧</div>
      <div class="tet_t">广电5G数字化生活系统</div>
      <div class="tet_h">SHIMAI IoE Solutions</div>
    </div>
    <div id="guanyu"></div>
    <div class="Introduc" id="guanyu">
      <div class="int_img"><img src="../assets/pic2.png" /></div>
      <div class="int_text">
        <div class="int_text_all">
          <div class="int-T"></div>
          <div class="int-wen">
            <span class="shu"></span> 时迈智慧科技有限公司
          </div>
        </div>
        
        <!-- <div class="int_text-wen"> -->
        <!-- <p> -->
        <!-- &nbsp; &nbsp;<span class="bood">“时迈智慧科技有限公司”</span> -->
        <!-- 于2021年7月1日在国家市场监管总局注册成立，致力于新一代无线数字融合网络技术研究与应用。 -->
        <!-- 成立于2021年7月1日。 -->
        <!-- </p> -->

        <!-- <p> -->
        <!-- &nbsp; &nbsp;<span class="bood">“时迈”</span>两字源于《诗经·周颂·时迈》<span class="bood" -->
        <!-- >“时迈其邦，昊天其子之，实右序有周”</span>,取传承中华文明博大精深之名、寓守护人居社会幸福快乐之行。 -->
        <!-- ，取传承中华文明的博大精深之名、寓企业开疆拓土的无往不胜之意。 -->

        <!-- 5.31日修改 -->
        <!-- </p> -->
        <!-- 公司秉承古老哲学中“九层之台，起于累土；千里之行，始于足下”之精神，脚踏实地、成就梦想。   6.7日临时修改 -->
        <!-- <p> -->
        <!-- &nbsp; &nbsp;  &nbsp;&nbsp;公司秉承古老哲学中 <span class="bood">“九层之台，起于累土；千里之行，始于足下”</span>之精神，脚踏实地、成就梦想。 -->
        <!-- </p> -->
        <!-- <p> -->
        <!-- &nbsp; &nbsp;&nbsp; -->
        <!-- 公司成立之初即制定4+4战略发展计划，旨在数字中国的建设发展之大时代中，践行行业领军企业的努力目标。公司目前已布局深圳南山研发中心、石家庄国家级高新开发区运营中心以及雄安新区智能住宅工程示范中心等三大基地。 -->
        <!-- 公司成立之初即制定4+4战略发展计划，旨在数字中国的建设发展之大时代中，践行努力学习行业标杆企业的目标。公司目前已布局深圳南山研发中心、石家庄国家级高新开发区运营中心以及雄安新区智能住宅工程示范中心等三大基地。 -->
        <!-- </p> -->
        <!-- <p> -->
        <!-- &nbsp; &nbsp;&nbsp; -->
        <!-- 公司尤其重视新生代人才的引入和培养，注重人才的个性化成长路径及发展空间，并将不断探索企业与优秀员工成长价值的合理分享机制，极力营造一个开明务实、人尽其才的企业工作氛围。 -->
        <!-- 公司尤其重视新生代人才的引入和培养，注重人才的个性化成长路径及发展空间，并将不断探索企业与优秀员工成长价值的合理分享机制，极力营造一个开明务实、人尽其才的企业工作氛围。 -->
        <!-- 5.31日修改 -->
        <!-- </p> -->
        <!-- </div> -->
        <!-- 7.25 修改 -->
        <div class="int_text-wen">
          <p class="bood title">公司简介</p>
          <p><span class="bood">“时迈智慧科技有限公司”</span> 成立于2021年7月1日。</p>
          <p class="suo">
            公司成立之初即制定4+4战略发展计划，旨在数字中国的建设发展之大时代中，践行努力学习行业标杆企业的目标。公司目前已布局深圳南山研发中心、石家庄国家级高新开发区运营中心以及雄安新区智能住宅工程示范中心等三大基地。
            公司尤其重视新生代人才的引入和培养，注重人才的个性化成长路径及发展空间，并将不断探索企业与优秀员工成长价值的合理分享机制，极力营造一个开明务实、人尽其才的企业工作氛围。
            2023年，公司获得<span class="bood">“国家级科技型中小企业”</span> 和<span class="bood">“河北省科技型中小企业”</span>称号。
          </p>
          <p class="bood title">经营范围</p>
          <p class="suo">
            计算机软硬件及系统、电子产品、智能产品、广播电视设备的研发、生产和销售；安防工程、建筑工程、电子与智能化工程、网络工程的设计、施工及技术服务；网络综合布线；机电设备安装，计算机信息系统集成服务；通讯器材、电力设备、家用电器、仪器仪表、照明器材、环保设备、节能产品、管材管件的销售等。
          </p>
          <p class="bood title">公司文化</p>
          <p class="suo">
            <span class="bood">“时迈”</span> 两字源于《诗经·周颂·时迈》<span class="bood">“时迈其邦，昊天其子之，实右序有周”</span>，取传承中华文明的博大精深之名、寓守护人居社会幸福快乐之行。
            公司秉承古老哲学中<span class="bood">“九层之台，起于累土；千里之行，始于足下”</span>之精神，脚踏实地、成就梦想。
          </p>
          <p class="bood title">产品与服务</p>
          <p class="suo">
            国内创新5G数字化生活系统，自主研发出<span class="bood">“可移动智慧中屏（iMXP）”</span> 新品类，重新定义了住宅家庭的数字化消费新模式，解决建筑智能化等行业痛点和发展刚需，使得用户全场景数字化生活得以实现。
            2023年，5G数字化生活系统产品喜获国标检验检测报告及《中国国家强制性产品认证证书》。
          </p>
          <p class="bood title">知识产权</p>
          <p class="suo">
            公司业已取得了《无线电通讯的智能抗干扰方法、装置、设备及介质》、《楼宇微基站系统》、《基于微基站的楼宇对讲云平台系统》、《用于广播电视的分布式天线系统》、《安防报警管理软件1.0》、《数字电视管理软件1.0》、《智慧社区云平台管理软件1.0》、《智慧n微基站软件1.0》、《IoE物联网管理系统软件1.0》、《无线数字化楼宇可视对讲系统管理软件1.0》、《无线数字物联网智慧中屏软件1.0》、《无线数字物联网智慧终端软件1.0》、《无线数字物联网智慧门口终端软件1.0》、《IoE物联网智慧社区手机app软件安卓版1.0》、《IoE物联网智慧社区手机appIOS软件版1.0》等诸多专利和计算机软件著作权。
          </p>
        </div>
      </div>
    </div>
    <div class="life" id="jiagou">
      <div class="life-bia">5G数字化生活方案架构</div>
      <div class="life-int lif-top">
        <div class="int-im"></div>
        <div class="int-wall">
          <p class="int-o">全无线化智慧社区</p>
          <p class="int-t">全无线化楼宇对讲、</p>
          <p class="int-t">智慧中屏入户</p>
        </div>
      </div>
      <div class="life-int">
        <div class="int-im two"></div>
        <div class="int-wall">
          <p class="int-o">5G数字化家庭</p>
          <p class="int-t">全面提升用户</p>
          <p class="int-t">覆盖率及粘度</p>
        </div>
      </div>
      <div class="life-int">
        <div class="int-im the"></div>
        <div class="int-wall">
          <p class="int-o">用户</p>
          <p class="int-t">点播、回放、投屏、网购</p>
          <p class="int-t">社区团购、智能家居、畅享5G</p>
        </div>
      </div>
    </div>
    <div class="shequ">
      <div class="shimai">时迈IoE物联网智慧社区</div>
      <div class="shequ-img">
        <!-- <img src="../assets/zhuihuishequ.png" alt="" /> -->
      </div>
    </div>
    <div class="core">
      <div class="coretext" id="youshi">系统核心优势</div>
      <div class="coredit">
        <div class="coreditx core-left">
          <div class="coreditx-all">
            <div class="coredit-top">
              <img src="../assets/2.png" /> 住宅刚需
            </div>
            <div class="coredit-btm">楼宇对讲是新建住宅验收标准</div>
          </div>
          <div class="coreditx-all">
            <div class="coredit-top"><img src="../assets/2.png" />造价低</div>
            <div class="coredit-btm">
              工序简化，节省人工，施工周期短，综合造价低。
            </div>
          </div>
          <div class="coreditx-all">
            <div class="coredit-top"><img src="../assets/2.png" />线上巡检</div>
            <div class="coredit-btm">
              后期维护简单，自动发现问题，把售后服务做在最前端
            </div>
          </div>
          <div class="coreditx-all">
            <div class="coredit-top"><img src="../assets/2.png" />持续运营</div>
            <div class="coredit-btm">
              支持图片信息广告发布，支持5G云电视播放，支持商城运营，实现可持续化。
            </div>
          </div>
        </div>
        <div class="coreditx core—right">
          <div class="coreditx-all">
            <div class="coredit-top"><img src="../assets/2.png" />免管线</div>
            <div class="coredit-btm">
              基于广电5G专网技术，免开槽、免穿管布线。
            </div>
          </div>
          <div class="coreditx-all">
            <div class="coredit-top"><img src="../assets/2.png" />短工期</div>
            <div class="coredit-btm">无需传统组网，大量节省工期。</div>
          </div>
          <div class="coreditx-all">
            <div class="coredit-top"><img src="../assets/2.png" />安装便捷</div>
            <div class="coredit-btm">磁吸式安装，可移动化，使用便捷</div>
          </div>
          <div class="coreditx-all">
            <div class="coredit-top"><img src="../assets/2.png" />在线互通</div>
            <div class="coredit-btm">
              业主与物业之间通过5G专网实现在线互通，提高物业服务品质。
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="Product" id="jieshao">
      <div class="Product-int">产品介绍</div>
      <div class="Product-show">
        <div>
          <img src="../assets/pic4.png" />
          <p>10寸门口机</p>
        </div>
        <div>
          <img src="../assets/pic5.jpg" />
          <p>10广电智慧屏</p>
        </div>
        <div>
          <img src="../assets/4.jpg" />
          <p>8寸广电智慧屏</p>
        </div>
        <div>
          <img src="../assets/5.jpg" />
          <p>7寸广电智慧屏</p>
        </div>
      </div>

      <div class="Product-show">
        <div>
          <img src="../assets/6.jpg" />
          <p>管理机</p>
        </div>
        <div>
          <img src="../assets/7.jpg" />
          <p>5G数字化生活IOE管控平台</p>
        </div>
        <div>
          <img src="../assets/pic7.png" />
          <p>IOE物联网智慧社区</p>
        </div>
        <div>
          <img src="../assets/8.jpg" />
          <p>微基站</p>
        </div>
      </div>
    </div>
    <div class="Classic" id="jingdian">
      <div class="Classic-caks">部分经典案例</div>
      <div class="Classic-show">
        <div class="one">
          <img src="../assets/pic9.png" />
          <p>杭州 融创杭州印</p>
        </div>
        <div class="two">
          <img src="../assets/pic10.png" />
          <p>深圳京基金融中心</p>
        </div>
        <div class="the">
          <img src="../assets/pic11.png" />
          <p>广州亚运村</p>
        </div>
      </div>
    </div>
    <div class="partner-all" id="hezuo">
      <div class="partner">战略合作伙伴</div>
      <ul class="par-int">
        <li><img src="../assets/L1.png" alt="" /></li>
        <li><img src="../assets/L2.png" alt="" /></li>
        <li><img src="../assets/L3.png" alt="" /></li>
        <li><img src="../assets/L4.png" alt="" /></li>
        <li><img src="../assets/L5.png" alt="" /></li>
        <li><img src="../assets/L6.png" alt="" /></li>
        <li><img src="../assets/L7.png" alt="" /></li>
        <li><img src="../assets/L8.png" alt="" /></li>
        <li><img src="../assets/L9.png" alt="" /></li>
        <li><img src="../assets/L10.png" alt="" /></li>
        <li><img src="../assets/L11.png" alt="" /></li>
        <li><img src="../assets/L12.png" alt="" /></li>
        <li><img src="../assets/L13.png" alt="" /></li>
        <li><img src="../assets/L14.png" alt="" /></li>
        <li><img src="../assets/L15.png" alt="" /></li>
        <li><img src="../assets/L16.png" alt="" /></li>
        <li><img src="../assets/L17.png" alt="" /></li>
        <li><img src="../assets/L18.png" alt="" /></li>
        <li><img src="../assets/L19.png" alt="" /></li>
        <li><img src="../assets/L20.png" alt="" /></li>
      </ul>
    </div>
    <!-- <Dimensional></Dimensional> -->
    <div class="Details">
      <div class="Details-t one">电话：0311-85093912</div>
      <div class="Details-t two">地址：河北省石家庄市裕华区海河道29号</div>
      <div class="Details-t the">
        版权所有：时迈智慧科技有限公司 备案号：冀ICP备2022029189号
      </div>
    </div>
  </div>
</template>
<script>
import "./hello.scss";
import min from "./min";

export default {
  data() {
    return {};
  },
  methods: {
    go() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    gohe() {
      // document.getElementById("hezuo").scrollIntoView({ behavior: "smooth" });
      console.log(window);
      window.scrollTo({
        top: 6152,
        left: 0,
        behavior: "smooth",
      });
    },
    gedian() {
      document
        .getElementById("jingdian")
        .scrollIntoView({ behavior: "smooth" });
    },
    gochan() {
      document.getElementById("jieshao").scrollIntoView({ behavior: "smooth" });
    },

    goyou() {
      document.getElementById("youshi").scrollIntoView({ behavior: "smooth" });
    },
    gofang() {
      document.getElementById("jiagou").scrollIntoView({ behavior: "smooth" });
    },
    goguan() {
      document.getElementById("guanyu").scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>
 <style lang="scss">
</style>