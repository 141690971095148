<template>
  <div>
  <vue-qr :text="downloadData.url" :margin="10" colorDark="#000" colorLight="#fff" :dotScale="1" :logoSrc="downloadData.icon" :logoScale="0.2" :size="200"></vue-qr>
  </div>
</template>
 
<script>
import vueQr from 'vue-qr'
export default {
    components: {
        vueQr
    },
    data() {
        return {
            downloadData: {
                url: 'https://www.baidu.com/',  //需要转化成二维码的网址
                // icon: require('./xxx.jpg')  //二维码中间的图片,可以不设置
            }
        }
    }
}
</script>
